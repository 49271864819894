@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

body::-webkit-scrollbar {
  display: none !important;
}
body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;

  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}
