
  .str-chat__list {
    --webkit-overflow-scrolling: touch;  
    p {
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 1.2;
    }
  }
  
  .messaging.str-chat .str-chat__list::-webkit-scrollbar-thumb {
    background: #cecece !important;
  }