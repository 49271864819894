* {
  font-family: "Poppins", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100vh;
  background: #f1f1f1;
  scroll-behavior: smooth;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pac-container {
  z-index: 10000 !important;
}

.search {
  position: relative;
  font-family: Poppins;
  .search-bar {
    width: 100%;
    background: #f1f1f1 0% 0% no-repeat padding-box;
    box-shadow: 2px 2px 8px #455e7c0c;
    border-radius: 32px;
    height: 48px;
    padding-left: 45px;
    color: #264265;
    &:focus-visible {
      outline: none;
    }
  }
  img {
    position: absolute;
    top: 16px;
    left: 16px;
  }
}

input,
textarea {
  &:focus-visible {
    outline: none;
  }
}

.card-headings {
  font: normal normal 500 18px/25px Poppins;
  letter-spacing: 0px;
  color: #264265;
  // margin-bottom: 16px;
}
.text-custom-head {
  color: var(--unnamed-color-000000);
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #000000cc;
  opacity: 1;
}

.secondary-heading {
  font: normal normal 400 16px/25px Poppins;
  letter-spacing: 0px;
  color: #26426599;
}

.text-secondary {
  font: normal normal 400 16px/25px Poppins;
  letter-spacing: 0px;
  color: #264265cc;
}
.transparent-select {
  position: relative !important;
  right: -10px;
  .MuiSelect-icon {
    color: #597599;
  }
  div {
    padding: 0.5rem 2rem 0.5rem 1rem !important;
    color: #597599;
  }
  svg {
    z-index: 1;
  }
  fieldset {
    border: none;
    background-color: transparent;
    padding: 0rem 0.5rem;
  }
  .MuiSelect-select {
    z-index: 1;
  }
}

.errors {
  color: red;
  font-size: 12px;
  margin: 2px;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.hide-scrollbar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.user-name {
  text-align: left;
  font: normal normal 500 18px/27px Poppins;
  letter-spacing: 0px;
  color: #264265;
  opacity: 1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

// Toast Styles
.Toastify__toast-container {
  border-radius: 0.5rem;
  width: auto;
  font-size: 14px;
}
.Toastify__toast {
  min-height: 3rem;
}
.Toastify__toast-body {
  padding: 0 0.375rem;
  margin: 0;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}

.word-between-lines {
  display: flex;
  flex-direction: row;
}


.word-between-lines:before,
.word-between-lines:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #f7f8fa;
  margin: auto;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.text-custom > .CircularProgressbar-text {
  transform: translate(-15px, 5px);
}
.str-chat-channel.messaging .str-chat__main-panel {
  padding: 0 !important;
  height: 100% !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}